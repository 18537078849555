import React from 'react'
import logo from "../assets/logo_small.jpg"
import Cart from "./Cart"
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import "./styles/navbar.css"
import NavCategories from './NavCategories';

const Navbar = ( { removeFromCart } ) => {
  return (
    <>
    <nav className='nav'>
    <nav className='nav_container'>
      <a href="/" className='nav_logo_container'>
        <img src={logo} />
      </a>
      <nav className='nav_2'>
      <ul>
      <NavCategories />
      <li><a href="/">الرئيسية</a></li>
      <li><a href="/aboutus">من نحن</a></li>
      <li><a href="/check/order/">متابعة الطلب</a></li>
      <li><a href="#contact">تواصل معنا</a></li>
      </ul>
      
    </nav>
      <div className='nav_btns'>
        <Cart removeFromCart={removeFromCart} />
      </div>
    </nav>
    </nav>
    <nav className='mobile_nav'>
      <a className='mobile_nav_link' href="/">
      <i class="fa-solid fa-house"></i>
      <h3>الرئيسية</h3>
      </a>
      <NavCategories />
      <a className='mobile_nav_link' href="/check/order/">
      <i class="fa-solid fa-eye"></i>
      <h3>متابعة طلب</h3>
      </a>
      <a className='mobile_nav_link' href="#">
      <div className='nav_btns'>
        <Cart removeFromCart={removeFromCart} />
      </div>
      </a>
    </nav>
    </>
  )
}

export default Navbar
