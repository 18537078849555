import React from 'react'
import "./styles/footer.css"
import logo from "../assets/logo.jpg"
const Footer = () => {
  return (
    <footer id="contact" className='footer'>
    <footer className='footer_container'>
      <div className='footer_info'>
        <img src={logo} />
        <div className='footer_info_text'>
            <h1>مطعم التقوي</h1>
            <p>الوجهة الأولى في مصر لتذوق أشهى وأطيب أنواع المندي المعد بأعلى درجات الاحترافية والخبرة التي تمتد لأكثر من 30 عامًا</p>
        </div>

      </div>
      <div className='footer_contact'>
        <h1>تواصل معنا</h1>
        <div className='footer_contact_phone'>
        <a className='phone' href="tel:201002009822"><i class="fa-solid fa-phone"></i> 01002009822</a>
        <a className='phone' href="tel:201222903022"><i class="fa-solid fa-phone"></i> 01222903022</a>
        <a className='phone'><i class="fa-solid fa-phone"></i> 0226700081</a>
        <a className='phone'><i class="fa-solid fa-phone"></i> 0222720001</a>
        </div>

        <p className='footer_address'>٦٨ محمد المقلد، المنطقة الثامنة، مدينة نصر، محافظة القاهرة</p>

        <div className='footer_social'>
            <a href="https://api.whatsapp.com/message/EAE6TDN473F3E1"><i class="fa-brands fa-whatsapp"></i></a>
            <a href="https://www.facebook.com/eltaqwarestaurant"><i class="fa-brands fa-square-facebook"></i></a>
            <a href="https://www.instagram.com/eltaqwa_restaurant?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><i class="fa-brands fa-square-instagram"></i></a>
            <a href="https://www.tiktok.com/@eltaqwarestaurant"><i class="fa-brands fa-tiktok"></i></a>
        </div>
      </div>
    </footer>
    <footer className='footer_footer'>
    الحقوق محفوظة © 2024 | مطعم التقوي ™
    </footer>
    </footer>
  )
}

export default Footer
