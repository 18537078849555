import { useState, useEffect } from 'react'
import "./styles/home.css"
import axios from "axios"
import SimpleImageSlider from "react-simple-image-slider";
import 'react-slideshow-image/dist/styles.css'
import slider_two from "../assets/home/slider/2.png"
import slider_three from "../assets/home/slider/3.png"
import one from "../assets/home/1.jpeg"
import two from "../assets/home/2.jpeg"
import three from "../assets/home/3.jpeg"
import four from "../assets/home/4.jpeg"


const Home = () => {
  const [imageArray, setImageArray] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [dishes, setDishes] = useState([]);

  const fetchCat = () => {
    axios.get(`https://talkwebnow.online/admin/categories`, {
      headers: { 'Accept': 'application/json' }
    })
      .then(response => {
        console.log(response);
        setCategories(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };
  const fetchDishes = () => {
    axios.get(`https://talkwebnow.online/admin/products`, {
      headers: { 'Accept': 'application/json' }
    })
      .then(response => {
        console.log(response.data.data);
        setDishes(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };
  const fetchSliders = () => {
    axios.get(`https://talkwebnow.online/admin/sliders`, {
      headers: { 'Accept': 'application/json' }
    })
      .then(response => {
        console.log(response.data.data);
        setImageArray(response.data.data.map(item => ({ url: `https://talkwebnow.online/public/images/${item.image}`, caption: <a href="#">اطلب الأن</a> })))
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };



  useEffect(() => {
    fetchSliders();
    fetchCat();
    fetchDishes();
  }, []); // Empty dependency array to only run once

  


  return (
    <div className='home'>
      {imageArray && categories && dishes ? (
        <>
        <div className="slide-container">
        <SimpleImageSlider
        width="100%"
        height="166px"
        autoPlay = {true}
        autoPlayDelay={2}
        images={[{ url: `${slider_two}`},{ url: `${slider_three}`}].concat(imageArray)}
        showBullets={true}
      />
      </div>

      <div className='home_container'>

        <div className='home_dish'>
          <h1>الأصناف</h1>
          <div className='category_container'>
            {categories.map(category => (
              <a href={`/category/${category.id}`} className='category_sellers_item' key={category.id}>
                <div className='img_holder'>
                  <img src={`https://talkwebnow.online/public/images/${category.image}`} alt={category.title} />
                </div>
                {category.title}
              </a>
            ))}
          </div>
        </div>
        <div className='home_dish'>
          <h1>أفضل الأطباق</h1>
          <p className='swipe_info'>اسحب الشاشة الي اليمين <i class="fa-solid fa-arrow-left"></i></p>
          <div className='dish_container'>
          {dishes.slice(0, 10).map((dish, index) => (
  <a key={index} href={`/product/${dish.id}`} className='dish'>
    <span className='discount'>-{100 - ((dish.final_price / dish.initial_price) * 100).toFixed(0)}%</span>
    <div className='dish_img_holder'>
      <img src={`https://talkwebnow.online/public/images/${dish.image}`} alt={dish.title} />
    </div>
    <a href={`/product/${dish.id}`} className='dish_title'>
      {dish.title}
    </a>
    <div className='dish_prices'>
      <p className='before'>{dish.initial_price} EGP</p>
      <p className='final'>{dish.final_price} EGP</p>
    </div>
    <h2 className='add_to_cart_dish'><i className="fa-solid fa-cart-shopping"></i> أضف للسلة</h2>
  </a>
))}

          </div>
        </div>


        <div className='home_images'>
          <div className='home_images_2'>
            <img src={one} />
            <img src={two} />
            <img src={three} />
            <img src={four} />
          </div>
        </div>


      </div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d156362.7617143721!2d31.34511924552757!3d30.00351774694078!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583dd7b8e41161%3A0x97924baa5baa93e9!2z2YXYt9i52YUg2KfZhNiq2YLZiNmK!5e0!3m2!1sen!2sus!4v1714259166545!5m2!1sen!2sus" width="100%" height="450" padding="20" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </>
      ):(
        <></>
      )}
      
    </div>
  )
}

export default Home
