import React, { useState } from 'react';
import Orders from './admin/Orders';
import CreateDish from './admin/CreateDish';
import CreateCategory from './admin/CreateCategory';
import SliderEdit from './admin/SliderEdit';
import "./styles/admin.css";

const Admin = () => {
  const [selectedComponent, setSelectedComponent] = useState('Orders');
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleComponentChange = (componentName) => {
    setSelectedComponent(componentName);
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case 'Orders':
        return <Orders />;
      case 'CreateDish':
        return <CreateDish />;
      case 'CreateCategory':
        return <CreateCategory />;
      case 'SliderEdit':
        return <SliderEdit />;
      default:
        return null;
    }
  };

  return (
    <div className="admin">
    <button className='admin_nav_toggle' onClick={toggleNav}><i class="fa-solid fa-bars"></i></button>
      <div className={`admin_nav ${isNavOpen ? 'right' : ''}`}>
        <button onClick={toggleNav}><i className="fas fa-arrow-right"></i></button>
        <button onClick={() => handleComponentChange('Orders')}>طلبات</button>
        <button onClick={() => handleComponentChange('CreateDish')}>اضافة طبق جديد</button>
        <button onClick={() => handleComponentChange('CreateCategory')}>اضافة صنف</button>
        <button onClick={() => handleComponentChange('SliderEdit')}>تعديل الصفحة الرئيسية</button>
      </div>

      <div className='admin_components'>
        {renderSelectedComponent()}
      </div>
    </div>
  );
};

export default Admin;
