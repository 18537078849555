import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import order_check from "../assets/order_status/order_check.svg"
import "./styles/find_check_order.css"

const CheckOrder = () => {
    const [order_code, setOrder_code] = useState('');
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { value } = e.target;
        setOrder_code(value);
    };

    const fetchOrders = () => {
        axios.get(`https://talkwebnow.online/admin/orders/${order_code}`, {
            headers: { 'Accept': 'application/json' }
        })
            .then(response => {
                console.log(response.data.data.id);
                navigate(`/check/order/${response.data.data.id}`)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                toast.error("لا يوجد أوردر بهذا الكود")
            });
    };

    return (
        <div className='order_find'>
        <div className='order_find_container'>
            <div className='order_find_img_holder'>
                <img src={order_check}/>
            </div>

            <div className="input_holder">
                <label>كود الأوردر</label>
                <input
                  className="input_input"
                  placeholder="####"
                  name="name"
                  value={order_code}
                  onChange={handleInputChange}
                />
                <button onClick={fetchOrders} className='input_submit'>معاينة الأوردر</button>
              </div>
        </div>
        </div>
    );
};

export default CheckOrder;
