import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import ToggleSocialIcom from "../assets/ToggleSocialIcom.png"

const ToggleSocial = () => {
    const [toggle, setToggle] = useState(false);
    const props = useSpring({
        opacity: toggle ? 1 : 0,
        transform: toggle ? 'translateY(0)' : 'translateY(-20px)',
        config: { tension: 250, friction: 20 }
    });

    return (
        <div className='toggle_social'>
            <div onClick={() => setToggle(!toggle)}>
                {/* Replace 'icon.png' with your actual motorcycle icon image */}
                <img className='toggle_social' src={ToggleSocialIcom} alt="Toggle Icon" />
            </div>
            <animated.div className='toggle_social_links' style={props}>
                {/* Here list your social media icons */}
                <a className='toggle_social_link' href="https://api.whatsapp.com/message/EAE6TDN473F3E1" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-whatsapp"></i></a>
                <a className='toggle_social_link' href="https://www.facebook.com/eltaqwarestaurant" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-square-facebook"></i></a>
                <a className='toggle_social_link' href="https://www.instagram.com/eltaqwa_restaurant?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-square-instagram"></i></a>
                <a className='toggle_social_link' href="https://www.tiktok.com/@eltaqwarestaurant" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-tiktok"></i></a>
                <a className='toggle_social_link' href="https://www.google.com/maps/place/مطعم+التقوي%E2%80%AD/@30.048839,31.355533,12z/data=!4m6!3m5!1s0x14583dd7b8e41161:0x97924baa5baa93e9!8m2!3d30.0488393!4d31.355533!16s%2Fg%2F1tkhf97r?hl=ar&entry=tts" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-location-dot"></i></a>
                <a className='toggle_social_link' href="tel:201222903022" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-phone"></i></a>
                {/* Add more links as needed */}
            </animated.div>
        </div>
    );
};

export default ToggleSocial;
