import { useState, useEffect } from 'react'
import axios from "axios"
import "./styles/category.css"
import { useParams } from 'react-router-dom';

const CategoryProducts = () => {
    const { id } = useParams()

  const [categories, setCategories] = useState([]);
  const [dishes, setDishes] = useState([]);

  const fetchCat = () => {
    axios.get(`https://talkwebnow.online/admin/categories/${id}`, {
      headers: { 'Accept': 'application/json' }
    })
      .then(response => {
        console.log(response.data.data);
        setCategories(response.data.data);
        setDishes(response.data.data.products)
        console.log(`${`https://talkwebnow.online/public/images/${response.data.data.image}`}`)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    fetchCat();
  }, []);
  return (
    <>
      {dishes && dishes.length > 0 ? (
        <div className='home_dish'>
          <div className='category_landing' style={{ backgroundImage: `url(https://talkwebnow.online/public/images/${categories.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <h1>{categories.title}</h1>
          </div>
          <div className='dish_container category_dish_container'>
            {dishes.map((dish, index) => (
              <a key={index} href={`/product/${dish.id}`} className='dish'>
                <span className='discount'>-{100 - ((dish.final_price / dish.initial_price) * 100).toFixed(0)}%</span>
                <div className='dish_img_holder'>
                  <img src={`https://talkwebnow.online/public/images/${dish.image}`} alt={dish.title} />
                </div>
                <a href={`/product/${dish.id}`} className='dish_title'>
                  {dish.title}
                </a>
                <div className='dish_prices'>
                  <p className='before'>{dish.initial_price} EGP</p>
                  <p className='final'>{dish.final_price} EGP</p>
                </div>
                <h2 className='add_to_cart_dish'><i className="fa-solid fa-cart-shopping"></i> أضف للسلة</h2>
              </a>
            ))}
          </div>
        </div>
      ) : (
        <>
                <div className='home_dish'>
          <div className='category_landing' style={{ backgroundImage: `url(https://talkwebnow.online/public/images/${categories.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <h1>{categories.title}</h1>
          </div>
          <h1 className='no_product'>
          <i class="fa-solid fa-box-open"></i>
            لا يوجد أي أطباق في هذا التصنيف بعد</h1>
          </div>
        </>
      )}
    </>
  );
  
}

export default CategoryProducts
