import { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import "./styles/admin.css";
import { useParams } from 'react-router-dom';
import estelam_1 from "../assets/order_status/estelam_1.svg"
import estelam_2 from "../assets/order_status/estelam_2.svg"
import ta7der from "../assets/order_status/ta7der.svg"
import tawsel from "../assets/order_status/tawsel.svg"
import tam from "../assets/order_status/tam.svg"

const CheckOrder = () => {
    const { id } = useParams()
    const [order, setOrder] = useState();

    const fetchOrders = () => {
        axios.get(`https://talkwebnow.online/admin/orders/${id}`, {
            headers: { 'Accept': 'application/json' }
        })
            .then(response => {
                console.log(response.data.data);
                setOrder(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                toast.error(error.response.data.message)
            });
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    return (
        <div className='checkout'>
            {order ? (
                <>
                <div className='checkout'>
                    <div className='checkout_container'>
                        <h1 className='checkout_title'>الطلب</h1>
                        <div className='checkout_list'>
                        {order.order_details.map((orderItem, index) => (
                                <a href={`/product/${orderItem.id}`} className='cart_order' key={index}>
                                    <div className='order_info'>
                                    <div className='cart_order_img_holder'>
                                <img src={`https://talkwebnow.online/public/images/${orderItem.product_image}`} alt={orderItem.product_name} />
                            </div>
                                    <p className='cart_order_quantity'>x{orderItem.amount}</p>
                                    <p className='cart_order_title'>{orderItem.product_name}</p>
                                    <h6 className='cart_order_price'>{orderItem.amount * parseInt(orderItem.product_price)} EGP</h6>
                                    </div>
                                    <a href={`/product/${orderItem.id}`} className="positive_karar"><i class="fa-solid fa-eye"></i></a>
                                </a>
                            ))}
                            <div className='cart_orders_total checkout_total'>
                                <h1>الاجمالي</h1>
                                <p>{order.after_discount} EGP</p> {/* Display total price here */}
                            </div>
                        </div>
                        <div className='order_status'>
                            <p>كود الطلب : {order.id}</p>
                            {order.status === "استلام" ? (
    <>
        <img src={estelam_1} />
        <h1 className='order_status_title'>تم استلام طلبك .. سيتم تحضيره قريبا</h1>
    </>
) : order.status === "تحضير" ? (
    <>
        <img src={ta7der} />
        <h1 className='order_status_title'>يتم الان تحضير طلبك برجاء الانتظار</h1>
    </>
) : order.status === "توصيل" ? (
    <>
        <img src={tawsel} />
        <h1 className='order_status_title'>طلبك الان في الطريق سيتم التواصل معك قريبا</h1>
    </>
) : order.status === "تم" ? (
    <>
        <img src={tam} />
        <h1 className='order_status_title'>تم التوصيل .. نتمني ان تكون راضي عن الخدمة ❤️</h1>
    </>
) : (
    <></> // Default case when none of the conditions match
)}
                        </div>
                    </div>
                    <ToastContainer />
                    </div>
                    </>
            ) : (<></>)}

        </div>
    );
};

export default CheckOrder;
