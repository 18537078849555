import './styles/about_us.css';
import { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {


    return (
        <div className='about_us'>
            <div className='about_us_container'>
                <h1 className='makal_main_title'>مطعم التقوي: رحلة أصيلة إلى عالم المندي الفاخر</h1>
                <h1 className='makal_title'> مطعم التقوي أصالة الطعم سر نجاحنا</h1>
                <p className='makal_body'>نحن في مطعم التقوي نؤمن بأن سر نجاح أي طبق يكمن في جودة المكونات وطريقة تحضيرها. لذلك، نحرص على اختيار اللحوم الطازجة والمكونات الطبيعية عالية الجودة، لنضمن لكم تجربة لا تُنسى. إلى جانب ذلك، نحافظ على سرية وصفاتنا الخاصة التي توارثناها عبر الأجيال، والتي تضفي على طبق المندي لدينا نكهة مميزة لا تُقارن.</p>
                <h1 className='makal_title'>مطعم التقوي تجربة طهي متكاملة</h1>
                <p className='makal_body'>لا يقتصر اهتمامنا في مطعم التقوي على جودة الطعام فحسب، بل نحرص على تقديم تجربة طهي متكاملة ترضي كافة تطلعاتكم.</p>

                <h1 className='makal_title'>أهم ما يميز مطعم التقوي</h1>
                <p className='makal_body'>
                    تنوع أطباق المندي: نقدم لكم  في مطعم التقوي تشكيلة واسعة من أطباق المندي الشهية التي تناسب جميع الأذواق، سواء كنتم تفضلون الدجاج أو اللحم أو الرومي. كما نوفر لكم خيارات متنوعة من الحشو والاضافات لإضفاء لمسة شخصية على طبقكم المفضل.
                    الخبرة في المناسبات: يتمتع فريق عملنا بخبرة واسعة في تقديم خدمات الطعام للمناسبات والاحتفالات. سواء كانت مناسبة عائلية صغيرة أو حفلًا كبيرًا، يضمن لكم مطعم التقوي تقديم تشكيلة مميزة من الأطباق الشهية والخدمات الاحترافية التي تجعل مناسبتكم لا تُنسى.
                    خدمة توصيل سريعة: نوفر لكم خدمة توصيل سريعة إلى المنازل والمكاتب و المصانع و الشركات ، حتى تستمتعوا بطعم المندي الأصيل من مطعم التقوي في راحة تامة.
                    أجواء راقية: يوفر مطعم التقوي أجواء راقية وعصرية لتناول الطعام، حيث يمكنكم الاستمتاع بتناول وجباتكم برفقة العائلة والأصدقاء في أجواء مريحة.
                </p>
                <h1 className='makal_title'>رحلة لا تكتمل إلا بأفضل طبق محشي</h1>
                <p className='makal_body'>لا تكتمل وجبتكم في مطعم التقوي إلا بتذوق تشكيلة المحاشي اللذيذة والشهيرة التي نعدها بأنفسنا باستخدام أفضل أنواع المكونات.</p>
                <h1 className='makal_title'>مطعم التقوي عالم المندي الفاخر</h1>
                <p className='makal_body'>ندعوكم إلى زيارة مطعم التقوي أو طلب وجباتكم من خلال خدمة التوصيل السريعة لنخوض بكم رحلة إلى عالم المندي الفاخر الذي يتميز بطعمه الأصيل وعبق التقاليد.</p>
                <h1 className='makal_title'>تواصل مع مطعم التقوي</h1>
                <p className='makal_body'>يمكنكم التواصل معنا عبر الهاتف أو زيارة موقعنا الإلكتروني للاطلاع على قوائم الطعام وطلبات الحجز والاستفسارات. يسعد فريق مطعم التقوي بخدمتكم وتلبية احتياجاتكم.</p>
            </div>
        </div>
    );
}

export default App;
