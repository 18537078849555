import React, { useEffect,useState } from 'react';
import one from "../assets/notification/1.png"
import two from "../assets/notification/2.png"
import three from "../assets/notification/3.png"
import four from "../assets/notification/4.png"
import five from "../assets/notification/5.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const messages = [
    {
      text: "١٣٦ زبونًا طلبوا هذا الطبق اليوم",
      imageUrl: one
    },
    {
      text: "٨٥ زبونًا طلبوا هذا الطبق اليوم",
      imageUrl: two
    },
    {
      text: "٥٠ زبونًا طلبوا هذا الطبق اليوم",
      imageUrl: three
    },
    {
      text: "١٢٠ زبونًا طلبوا هذا الطبق اليوم",
      imageUrl: four
    },
    {
      text: "١٠٠ زبونًا طلبوا هذا الطبق اليوم",
      imageUrl: five
    }
  ];
  
  

  const CustomToast = ({ message, imageUrl }) => {
    return (
      <div style={{
          display: 'flex',
          flexDirection: 'row-reverse', // This reverses the order of flex items
          alignItems: 'center',
          width: '100%',
          textAlign: 'right',
          fontSize: "13px",
          height: "20px"
      }}>
        <img src={imageUrl} alt="Image" style={{
            width: '40px',
            height: '40px',
            marginLeft: '10px' // Adjust spacing on the correct side
        }} />
        <div>{message}</div>
      </div>
    );
  };
  

  const NotificationComponent = () => {
    const [isActive, setIsActive] = useState(true);
  
    const notify = () => {
      const randomIndex = Math.floor(Math.random() * messages.length);
      const { text, imageUrl } = messages[randomIndex];
      toast(<CustomToast message={text} imageUrl={imageUrl} />, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        pauseOnFocusLoss: false
      });
    };


  useEffect(() => {
    let interval;
    if (isActive) {
      interval = setInterval(notify, 5000);
    }
    return () => clearInterval(interval);
  }, [isActive]);

  useEffect(() => {
    const handleFocus = () => setIsActive(true);
    const handleBlur = () => setIsActive(false);

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  return (
    <div>
      <ToastContainer />
    </div>
  );
}

export default NotificationComponent;
