import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useState,useEffect} from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About_us from "./components/About_us";
import Admin from "./components/Admin";
import SingleDish from "./components/SingleDish";
import SingleOrder from "./components/admin/SingleOrder";
import FirstFindCheckOrder from "./components/FirstFindCheckOrder";
import FindCheckOrder from "./components/FindCheckOrder";
import CheckOrder from "./components/CheckOrder";
import Checkout from "./components/Checkout";
import Footer from "./components/Footer";
import CategoryProducts from './components/CategoryProducts';
import ToggleSocial from './components/ToggleSocial';
import Notification from './components/Notification';

function App() {
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")) || []);

  const addToCart = (item) => {
    const existingItemIndex = cart.findIndex(cartItem => cartItem.dish.title === item.dish.title);
  
    if (existingItemIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingItemIndex].quantity += item.quantity;
      setCart(updatedCart);
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    } else {
      const updatedCart = [...cart, item];
      setCart(updatedCart);
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    }
    return false;
  };
  const removeFromCart = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    return false;
  };
  
  
  return (
    <BrowserRouter>
     <Navbar removeFromCart={removeFromCart} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<About_us />} />
        <Route path="/category/:id" element={<CategoryProducts />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/product/:id" element={<SingleDish addToCart={addToCart}/>} />
        <Route path="/admin/order/:id" element={<SingleOrder/>} />
        <Route path="/check/order/:id" element={<CheckOrder/>} />
        <Route path="/first/check/order/" element={<FirstFindCheckOrder/>} />
        <Route path="/check/order/" element={<FindCheckOrder/>} />
        <Route path="/checkout" element={<Checkout removeFromCart={removeFromCart}/>} />
      </Routes>
      <ToggleSocial />
      <Notification />
    <Footer />
    <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
