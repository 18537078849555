import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import DataTable, { SortOrder } from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import ImageUploader from 'react-image-upload';
import 'react-image-upload/dist/index.css';
import '../styles/create_dish.css';

const CreateCategory = () => {
  const [categories, setCategories] = useState([])
  const [dishData, setDishData] = useState({
    name: '',
    image: null,
  });

  const fetchCat = () => {
    axios.get(`https://talkwebnow.online/admin/categories`, {
      headers: { 'Accept': 'application/json' }
    })
      .then(response => {
        console.log(response);
        setCategories(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        toast.error(error.response.data.message)
      });
  };

  useEffect(() => {
    fetchCat();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDishData({
      ...dishData,
      [name]: value,
    });
    console.log(dishData)
  };

  const getImageFileObject = (imageFile) => {
    setDishData({
      ...dishData,
      image: imageFile.file,
    });
  };

  const runAfterImageDelete = (file) => {
    console.log({ file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', dishData.name);
    formData.append('image', dishData.image);

    try {
      const response = await axios.post('https://talkwebnow.online/admin/categories/add_category', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      toast.success("تم انشاء تصنيف جديد بنجاح")
      setDishData({
        name: '',
        image: null,
      })
      fetchCat()
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error(error.response.data.message)
    }
  };

  const handleDeleteCategory = async (id, e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(`https://talkwebnow.online/admin/categories/delete/${id}`,{
        headers: {
          'Content-Type': 'multipart/form-data',
        },

      });
      toast.success("تم مسح الطبق بنجاح")
      console.log(response.data);
      fetchCat()
    } catch (error) {
      console.error('Error uploading file:', error.response.data.message);
      toast.error(error.response.data.message)
    }
};

  const columns = [
    {
      name: 'اسم التصنيف',
      sortable: true,
      selector: row => row.title
    },
    {
      name: 'قرارات',
      selector: row => <>
        <a href={`/category/${row.id}`} target="_blank" className="positive_karar"><i class="fa-solid fa-eye"></i></a>
        <button onClick={(e) => handleDeleteCategory(row.id, e)} className='negative_karar'><i class="fa-solid fa-trash"></i></button>
      </>
    },



  ];



  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', dishData.name);
    formData.append('image', dishData.image);

    try {
      const response = await axios.patch('https://talkwebnow.online/admin/categories/16', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      toast.success("تم انشاء تصنيف جديد بنجاح")
      setDishData({
        name: '',
        image: null,
      })
      fetchCat()
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error(error.response.data.message)
    }
  };
  return (
    <div className='create_delete'>
      {categories ? (
        <>
        <button onClick={handleUpdate}>sdfsdf</button>
          <form className="create_form" onSubmit={handleSubmit}>
            <div className="create_form_container">
              <h1>تصنيف جديد</h1>
              <div className="input_holder">
                <label>اسم التصنيف</label>
                <input
                  className="input_input"
                  placeholder="أدخل اسم التصنيف"
                  name="name"
                  value={dishData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input_holder">
                <label>صورة التصنيف</label>
                <ImageUploader
                  className="input_img"
                  style={{ height: 220, width: 220, background: 'rgb(255 255 255)' }}
                  onFileAdded={(img) => getImageFileObject(img)}
                  onFileRemoved={(img) => runAfterImageDelete(img)}
                />
              </div>
              <input type="submit" className="input_submit" value="أضف التصنيف" />
            </div>
            <ToastContainer />
          </form>
          <div className='table_div'>
            <DataTable
              columns={columns}
              data={categories}
              pagination
              striped
              defaultSortAsc={false}
            />
          </div>
        </>
      ) : (
        <></>
      )}

    </div>
  );
};

export default CreateCategory;
