import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageUploader from 'react-image-upload';
import 'react-image-upload/dist/index.css';
import "../styles/orders.css"
import DataTable, { SortOrder } from 'react-data-table-component';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

const Orders = () => {
  const [orders, setOrders] = useState([]);

  const fetchOrders = () => {
    axios.get(`https://talkwebnow.online/admin/orders`, {
      headers: { 'Accept': 'application/json' }
    })
    .then(response => {
      console.log(response.data.data);
      setOrders(response.data.data);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      toast.error(error.response.data.message)
    });
  };
  
  useEffect(() => {
    fetchOrders();
  }, []);

  const columns = [
    {
      selector: row => <>
        <a href={`admin/order/${row.id}`} className="positive_karar"><i className="fa-solid fa-eye"></i></a>
      </>,
      name: 'معاينة'
    },
    {
      selector: row => row.status,
      name: 'الحالة'
    },
    {
      selector: row =>           <a
      data-tooltip-id="my-tooltip"
      data-tooltip-content={`${row.note}`}
      data-tooltip-place="top"
    >
      <i class="fa-solid fa-eye"></i>
    </a>,
      name: 'ملحوظة'
    },
    {
      selector: row => `${row.after_discount} LE`,
      name: 'سعر الطلب'
    },
    {
      selector: row => {
        return (
          <a
            data-tooltip-id="my-tooltip"
            data-tooltip-content={`${row.address_1} ${row.address_2}`}
            data-tooltip-place="top"
          >
            {row.address_1} ...
          </a>
        );
      },
      name: 'العنوان'
    },
    {
      selector: row => row.phone,
      name: 'رقم الهاتف'
    },
    {
      selector: row => row.name,
      name: 'الاسم'
    },
    {
      selector: row => {
        const hour = new Date(row.created_at).getHours();
        const minute = new Date(row.created_at).getMinutes();
        const formattedDate = `${hour}:${minute}`;
        return formattedDate;
      },
      name: 'الساعة',
      sortable: true
    },
    {
      selector: row => {
        const date = new Date(row.created_at);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
      },
      name: 'تاريخ',
      sortable: true
    }
  ];
  

  return (
    <>
      {orders ? (
        <div className='table_div'>
          <DataTable
            columns={columns}
            data={orders}
            pagination
            striped
            defaultSortAsc={false}
          />
          <Tooltip id="my-tooltip" place="top" />
          <ToastContainer />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Orders;
